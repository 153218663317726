<template>
    <h-row justify="center">
        <h-col cols="auto">
            <h1 class="pb-4">No {{ type ? type : 'data' }} available</h1>
            <v-btn v-if="modelsName" color="primary" block :to="'/secure/' + modelsName + '/form'" exact>
                <span>AGGIUNGI</span>
                <v-icon class="pl-4">fa-external-link-alt</v-icon>
            </v-btn>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'NoDataAvailable',
        components: {HRow, HCol},
        props: {modelsName: String, type: String}
    }
</script>

<style scoped>

</style>
