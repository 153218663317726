<template>
    <unauthorized
        v-if="!!notFound || $store.getters.unauthorized.includes('/secure/' + (!routesName ? modelsName : routesName) + '/scheda')"
    ></unauthorized>

    <h-row class="mt-4" v-else-if="!isLoading" justify="center">
        <h-col class="py-0" v-if="model.ID_REC">
            <slot name="header" :model="model"></slot>
            <v-divider></v-divider>
            <slot name="titolo" :model="model"></slot>
            <slot name="generale" :model="model"></slot>

            <slot :name="scheda.nome"
                  v-for="scheda in schede ? schede.slice().filter(s => !s.showIf || s.showIf(model)) : []"
                  :model="model"
            >
                <component :is="scheda.component" :model="model" :routes-name="routesName"></component>
            </slot>
        </h-col>

        <no-data-available v-else :models-name="!routesName ? modelsName : routesName"></no-data-available>
    </h-row>

    <loading-resouces v-else icon="fa-cubes" messaggio="Recupero delle informazioni in corso"></loading-resouces>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import Unauthorized from '@/components/aaaGenerics/commons/Unauthorized';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';

    export default {
        name: 'BaseScheda',
        components: {HRow, HCol, NoDataAvailable, LoadingResouces, Unauthorized},
        props: {modelsName: String, routesName: String, schede: Array},
        data: () => ({
            isLoading: false,
            apiTimer: false,
            notFound: false,
            model: {}
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            id: function () {
                return this.$route.query.id;
            }
        },
        watch: {
            id: function () {
                this.initCheck();
            }
        },
        methods: {
            initCheck() {
                if (/^\d+$/.test(this.id)) {
                    this.loadModel();
                } else {
                    this.notFound = true;
                    window.history.back();
                }
            },
            loadModel() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET del dettaglio del modello
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_BASE_SINGLE',
                            paylod: {modelsName: _this.modelsName, id: _this.id},
                            doResponse: model => {
                                _this.model = model;
                                _this.$emit('endLoadModel', model);
                            },
                            doClose: () => {
                                _this.isLoading = false;
                                if (!_this.model.ID_REC) {
                                    _this.notFound = true;
                                }
                            }
                        });
                    }
                }

                this.isLoading = true;
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);

            }
        },
        created() {
            this.initCheck();
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
