<template>
    <h-row align="center">
        <h-col class="pt-0 pb-1" cols="12" sm="auto" no-padding>
            <h-row align="center">

                <h-col class="py-0 pr-0" cols="auto">
                    <button-tooltip large
                                    icon
                                    icon-name="fa-globe-africa"
                                    color="primary"
                                    tooltip="Copia link per WEB"
                                    @click.stop="share()"
                    ></button-tooltip>
                </h-col>

                <h-col v-if="modelsName === 'eventi'" class="py-0 pr-0" cols="auto">
                    <button-tooltip large
                                    icon
                                    icon-name="fa-mobile-alt"
                                    color="primary"
                                    tooltip="Copia link per APP"
                                    @click.stop="share('applinks://hand-2-hand.it/app/s/eventi/' + $route.query.id)"
                    ></button-tooltip>
                </h-col>

                <h-col v-if="modelsName === 'eventi'" class="py-0 pr-0" cols="auto">
                    <button-tooltip large
                                    icon
                                    icon-name="fa-qrcode"
                                    color="primary"
                                    tooltip="Scarica QR Code"
                                    @click.stop="downloadQRCode"
                    ></button-tooltip>
                </h-col>

                <qr-code v-show="false"
                         ref="qrCode"
                         :text="'https://hand-2-hand.it/app/s/eventi/' + $route.query.id"
                ></qr-code>

                <h-col class="py-0">
                    <h2>{{ title }}</h2>
                </h-col>

            </h-row>
            <i class="caption">Da quì puoi visualizzare, modificare ed eliminare {{ modelRef }}</i>
        </h-col>

        <slot name="top"></slot>

    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'BaseSchedaHeader',
        components: {HRow, HCol, ButtonTooltip},
        props: {
            modelsName: String,
            routesName: String,
            model: Object,
            modelRef: String
        },
        data: () => ({
            dialog: false,
            isLoading: false,
            apiTimer: null
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            title: function () {
                return 'Scheda ' +
                       (!this.routesName ? this.modelsName : this.routesName).replace(
                           /\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                       ) + ' N°' + this.$route.query.id;
            }
        },
        methods: {
            share(link) {
                this.$store.dispatch('copyToClipboard', {
                    value: !link ? location.href : link,
                    text: 'Link copiato negli appunti'
                });
            },
            downloadQRCode() {

                const a = document.createElement('a');
                a.href = this.$refs.qrCode.$el.querySelector('img').src;
                a.download = 'qrcode.png';
                a.click();

            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
