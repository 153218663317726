<template>
    <h-row class="pt-6 pb-3" justify="center" align="center">
        <h-col cols="auto">
            <slot name="pre-titolo"></slot>
        </h-col>
        <h-col cols="auto">
            <h1>{{ titolo ? titolo : model.NOME }}</h1>
        </h-col>
        <h-col cols="auto" class="px-0">
            <button-tooltip v-if="canEdit"
                            large
                            icon
                            icon-name="fa-edit"
                            color="primary"
                            :disabled="isGlobalLoading"
                            :loading="isLoading"
                            :to="'/secure/' + (!routesName ? modelsName : routesName) + '/form?id=' + model.ID_REC"
                            exact
                            :tooltip="'Click per modificare ' + modelRef"
            ></button-tooltip>
        </h-col>
        <h-col cols="auto" class="px-0">
            <button-tooltip v-if="canDelete"
                            large
                            icon
                            icon-name="fa-trash"
                            color="error"
                            :disabled="isGlobalLoading"
                            :loading="isLoading"
                            :tooltip="'Click per eliminare ' + modelRef"
                            @click.stop="dialog = true"
            ></button-tooltip>
        </h-col>

        <conferma-dialog v-if="Boolean(dialog)"
                         :dialog="Boolean(dialog)"
                         :text="'Sei sicuro di voler eliminare ' + (titolo ? titolo : model.NOME) + '?'"
                         :check-text="!!checkText ? checkText : null"
                         @close="dialog = false"
                         @confermato="onConfermato"
        ></conferma-dialog>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import ConfermaDialog from '@/components/aaaGenerics/commons/ConfermaDialog';

    export default {
        name: 'BaseSchedaTitolo',
        components: {HRow, HCol, ConfermaDialog, ButtonTooltip},
        props: {
            modelsName: String,
            routesName: String,
            model: Object,
            modelRef: String,
            titolo: String,
            canEdit: Boolean,
            canDelete: Boolean,
            checkText: [String, Boolean]
        },
        data: () => ({
            dialog: false,
            isLoading: false,
            apiTimer: null
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        },
        methods: {
            onConfermato(isDropCascade) {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //DELETE del model
                        _this.$store.dispatch('defaultRequest', {
                            api: 'DELETE_BASE',
                            paylod: {modelsName: _this.modelsName, id: _this.model.ID_REC, isDropCascade},
                            doResponse: () => {
                                _this.$store.dispatch(
                                    'activeSnackbar', {color: 'success', text: 'Eliminazione avvenuta con successo'}
                                );
                                _this.$router.push('/secure/' + (!_this.routesName ? _this.modelsName : _this.routesName));
                            },
                            doClose: () => {
                                _this.dialog = false;
                                _this.isLoading = false;
                            }
                        });
                    }
                }

                this.isLoading = true;
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);
            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
