<template>
    <h-row class="pb-6" justify="center">
        <h-col cols="11" class="elevation-2" style="border-radius: 6px">
            <h-row justify="center">
                <h-col class="px-0 text-center">
                    <h2 class="pb-4">Generale</h2>
                    <v-divider></v-divider>
                </h-col>
            </h-row>
            <h-row class="py-6" justify="center" align="center">
                <h-col cols="11" sm="5" v-for="(item, index) in items" :key="index">
                    <i v-if="!item.noLabel">{{ item.label }}: </i>
                    <slot :name="item.label.toLowerCase().split(/\s/).join('_')" :item="item">
                        <strong style="word-break: break-all;">
                            {{ item.data || item.data === 0 ? item.data : 'No data available' }}
                        </strong>
                    </slot>
                </h-col>
            </h-row>
            <h-row>
                <h-col class="py-0">
                    <v-divider></v-divider>
                    <h-row justify="end">
                        <h-col class="pb-0 pr-6" cols="auto">
                            <i class="caption">{{ getCreator }}</i>
                        </h-col>
                    </h-row>
                </h-col>
            </h-row>
            <h-row v-if="model.MODIFIER">
                <h-col class="py-0">
                    <h-row justify="end">
                        <h-col class="pb-0 pr-6" cols="auto">
                            <i class="caption">{{ getModifier }}</i>
                        </h-col>
                    </h-row>
                </h-col>
            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import moment from '@/moment';

    export default {
        name: 'BaseSchedaGenerale',
        components: {HRow, HCol},
        props: {model: Object, items: Array},
        computed: {
            getCreator: function () {
                return 'Creato da ' + this.model.CREATOR + ' il ' +
                       moment.toDate(this.model.CREATOR_TIMESTAMP);
            },
            getModifier: function () {
                return 'Modificato l\'ultima volta da ' + this.model.MODIFIER +
                       ' il ' + moment.toDate(this.model.MODIFIER_TIMESTAMP);
            }
        }
    }
</script>

<style scoped>

</style>
